// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .disable-step_EKc2E{padding:0}html.app__layout--desktop .disable-step__jumbotron_N69l7{margin-bottom:8px}html.app__layout--desktop .disable-step__description_sl9Ue{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;color:var(--TextPrimary);margin-bottom:8px;text-align:center;margin-bottom:16px}html.app__layout--desktop .disable-step__description-link_Xfjvf{display:inline-block;-webkit-text-decoration:none;text-decoration:none}html.app__layout--desktop .disable-step__description-accent_E2jJv{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}html.app__layout--desktop .disable-step__form_JKs1n{width:100%;max-width:364px;margin-bottom:0}html.app__layout--desktop .disable-step__form-wrapper_OllAA{display:flex;flex-direction:column;align-items:center}html.app__layout--desktop .disable-step__link_pEOER{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;display:flex;align-items:center;justify-content:center;width:100%;max-width:364px;min-height:44px;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"disable-step": `disable-step_EKc2E`,
	"disable-step__jumbotron": `disable-step__jumbotron_N69l7`,
	"disable-step__description": `disable-step__description_sl9Ue`,
	"disable-step__description-link": `disable-step__description-link_Xfjvf`,
	"disable-step__description-accent": `disable-step__description-accent_E2jJv`,
	"disable-step__form": `disable-step__form_JKs1n`,
	"disable-step__form-wrapper": `disable-step__form-wrapper_OllAA`,
	"disable-step__link": `disable-step__link_pEOER`
};
export default ___CSS_LOADER_EXPORT___;
