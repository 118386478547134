import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { onBeforeUnmount, onMounted } from 'vue';
import { Tag } from '@leon-hub/tags';
import RouteName from '@leon-hub/routing-config-names';
import { VForm } from 'web/src/components/Form';
import { useTfaLoginRoutePage } from 'web/src/modules/two-factor-authentication/pages/TfaLoginRoutePage/composables';
import { openGoogleAuthenticator } from 'web/src/modules/two-factor-authentication/utils';
import { TfaAppLink } from 'web/src/modules/two-factor-authentication/types';
import TextSlotPlaceholder from 'web/src/components/TextSlotPlaceholder/components/TextSlotPlaceholder.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'TfaLoginRoutePage',
    setup (__props) {
        const tagDiv = Tag.DIV;
        const supportRouteLink = {
            name: RouteName.SUPPORT
        };
        const { schema, uiSchema, formRef, onTfaLoginRoutePageBeforeUnmount, onTfaLoginRoutePageMounted, submitLoginHandler } = useTfaLoginRoutePage();
        onMounted(onTfaLoginRoutePageMounted);
        onBeforeUnmount(onTfaLoginRoutePageBeforeUnmount);
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['tfa-login'])
            }, [
                _createVNode(TextSlotPlaceholder, {
                    class: _normalizeClass(_ctx.$style['tfa-login__intro']),
                    text: _ctx.$t('WEB2_TFA_ENTER_CODE'),
                    tag: _unref(tagDiv)
                }, {
                    app: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['tfa-login__google-link']),
                                onClick: _cache[0] || (_cache[0] = ($event)=>_unref(openGoogleAuthenticator)(_unref(TfaAppLink).GOOGLE_AUTHENTICATOR))
                            }, " Google Authenticator ", 2)
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "text",
                    "tag"
                ]),
                _createVNode(_unref(VForm), {
                    ref_key: "formRef",
                    ref: formRef,
                    class: _normalizeClass(_ctx.$style['tfa-login__form']),
                    schema: _unref(schema),
                    "ui-schema": _unref(uiSchema),
                    onSubmit: _unref(submitLoginHandler)
                }, null, 8, [
                    "class",
                    "schema",
                    "ui-schema",
                    "onSubmit"
                ]),
                _createVNode(_component_router_link, {
                    class: _normalizeClass(_ctx.$style['tfa-login__link']),
                    to: supportRouteLink
                }, {
                    default: _withCtx(()=>[
                            _createTextVNode(_toDisplayString(_ctx.$t('WEB2_TFA_NEED_HELP')), 1)
                        ]),
                    _: 1
                }, 8, [
                    "class"
                ])
            ], 2)), [
                [
                    _directive_auto_id,
                    'TfaLoginRoutePage'
                ]
            ]);
        };
    }
});
