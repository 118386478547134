// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .enable-steps_yrRUw{display:flex;flex-direction:column;row-gap:20px}html.app__layout--desktop .enable-steps__step_HEqpQ{padding:0 20px}html.app__layout--desktop .enable-steps__step-description_em9Gk{padding:0 8px}html.app__layout--desktop .enable-steps__form_tTwAc{padding:0;margin-top:24px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"enable-steps": `enable-steps_yrRUw`,
	"enable-steps__step": `enable-steps__step_HEqpQ`,
	"enable-steps__step-description": `enable-steps__step-description_em9Gk`,
	"enable-steps__form": `enable-steps__form_tTwAc`
};
export default ___CSS_LOADER_EXPORT___;
