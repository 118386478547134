// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-container_fBJSh{display:flex;flex-direction:column;row-gap:20px;align-items:center;margin:20px 0 0}.download-container__hint_f5D1p{margin-top:16px}.android__link_BxMWq,.ios__link_inTtk{display:block;overflow:hidden}.android__link_BxMWq{width:auto;height:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"download-container": `download-container_fBJSh`,
	"download-container__hint": `download-container__hint_f5D1p`,
	"android__link": `android__link_BxMWq`,
	"ios__link": `ios__link_inTtk`
};
export default ___CSS_LOADER_EXPORT___;
