// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-button-block_VOiOw{display:flex;flex-direction:column;align-items:center;padding:16px 8px;background-color:var(--Layer1);border-radius:5px}.action-button-block__label_ahLNH{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;margin-bottom:16px;color:var(--TextDefault);text-align:center;word-break:break-all}.action-button-block__button_N05gn{width:100%;padding:0 32px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"action-button-block": `action-button-block_VOiOw`,
	"action-button-block__label": `action-button-block__label_ahLNH`,
	"action-button-block__button": `action-button-block__button_N05gn`
};
export default ___CSS_LOADER_EXPORT___;
