import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, withDirectives as _withDirectives, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue";
const _hoisted_2 = [
    "src"
];
import { Tag } from '@leon-hub/tags';
import { IconName, IconSize } from '@leon-hub/icons';
import { VIcon } from '@components/v-icon';
import { TextSlotPlaceholder } from 'web/src/components/TextSlotPlaceholder';
import ActionLink from 'web/src/modules/two-factor-authentication/components/ActionLink/ActionLink.vue';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
import SNumberedList from 'web/src/components/NumberedList/SNumberedList/SNumberedList.vue';
import SNumberedListItem from 'web/src/components/NumberedListItem/SNumberedListItem/SNumberedListItem.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'FastOptionDesktopComponent',
    props: {
        qrCode: {},
        email: {}
    },
    emits: [
        "close"
    ],
    setup (__props, param) {
        let { emit: __emit } = param;
        const emit = __emit;
        const tagDiv = Tag.DIV;
        return (_ctx, _cache)=>{
            const _directive_data_test = _resolveDirective("data-test");
            _resolveDirective("auto-id");
            return _openBlock(), _createElementBlock("div", {
                key: 1,
                class: _normalizeClass(_ctx.$style['fast-option-desktop'])
            }, [
                _withDirectives((_openBlock(), _createBlock(_unref(TextSlotPlaceholder), {
                    class: _normalizeClass(_ctx.$style['fast-option-desktop__item']),
                    text: `1. ${_ctx.$t('WEB2_TFA_SETUP_PRESS_PLUS')}`,
                    tag: _unref(tagDiv)
                }, {
                    field: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['fast-option-desktop__item-accent'])
                            }, " \"" + _toDisplayString(_ctx.$t('WEB2_PLUS')) + "\" ", 3)
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "text",
                    "tag"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'fast-option-desktop__item fast-option-desktop__item-1'
                        }
                    ]
                ]),
                _withDirectives((_openBlock(), _createBlock(_unref(TextSlotPlaceholder), {
                    class: _normalizeClass(_ctx.$style['fast-option-desktop__item']),
                    text: `2. ${_ctx.$t('WEB2_SELECT_FIELD')}`,
                    tag: _unref(tagDiv)
                }, {
                    field: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['fast-option-desktop__item-accent'])
                            }, " \"" + _toDisplayString(_ctx.$t('WEB2_SKAN_QR')) + "\" ", 3)
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "text",
                    "tag"
                ])), [
                    [
                        _directive_data_test,
                        {
                            el: 'fast-option-desktop__item fast-option-desktop__item-2'
                        }
                    ]
                ]),
                _withDirectives((_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(_ctx.$style['fast-option-desktop__item'])
                }, [
                    _createTextVNode(" 3. " + _toDisplayString(_ctx.$t('WEB2_TFA_SETUP_SCAN_QR')), 1)
                ], 2)), [
                    [
                        _directive_data_test,
                        {
                            el: 'fast-option-desktop__item fast-option-desktop__item-3'
                        }
                    ]
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['fast-option-desktop__qr'])
                }, [
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['fast-option-desktop__corner'],
                            _ctx.$style['fast-option-desktop__corner-top']
                        ])
                    }, null, 2),
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['fast-option-desktop__corner'],
                            _ctx.$style['fast-option-desktop__corner-top--right']
                        ])
                    }, null, 2),
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['fast-option-desktop__corner'],
                            _ctx.$style['fast-option-desktop__corner-bottom']
                        ])
                    }, null, 2),
                    _createElementVNode("div", {
                        class: _normalizeClass([
                            _ctx.$style['fast-option-desktop__corner'],
                            _ctx.$style['fast-option-desktop__corner-bottom--right']
                        ])
                    }, null, 2),
                    _withDirectives(_createElementVNode("img", {
                        src: _ctx.qrCode,
                        alt: ""
                    }, null, 8, _hoisted_2), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: 'qr-code-image'
                            }
                        ]
                    ])
                ], 2),
                'N/A' !== _ctx.email ? (_openBlock(), _createBlock(ActionLink, {
                    key: 0,
                    title: _ctx.$t('WEB2_TFA_SETUP_QR_CODE_TROUBLE'),
                    onClick: _cache[0] || (_cache[0] = ($event)=>emit('close'))
                }, null, 8, [
                    "title"
                ])) : _createCommentVNode("", true)
            ], 2);
        };
    }
});
