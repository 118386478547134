import { MobileAppOs } from 'web/src/modules/app-download/enums';
import { DownloadBadgeData } from 'web/src/modules/two-factor-authentication/types';
export default function useEnableStepDownload(props) {
    const enabledLocales = [
        'az',
        'de',
        'en',
        'fr',
        'hi',
        'pt',
        'ru',
        'uc',
        'uz'
    ];
    const iosProperties = {
        appType: MobileAppOs.Ios,
        ...DownloadBadgeData.Ios
    };
    const androidProperties = {
        appType: MobileAppOs.Android,
        ...DownloadBadgeData.Android
    };
    function getUrl(url) {
        const fallbackLocale = 'en';
        const locale = props.languageTag?.split('_')[0] ?? fallbackLocale;
        const imageLocale = enabledLocales.includes(locale) ? locale : fallbackLocale;
        const imageSrc = `${url}${imageLocale}`;
        // eslint-disable-next-line import/no-dynamic-require
        return require(`web/src/assets/images/apps-logos/${imageSrc}.svg`);
    }
    return {
        iosProperties,
        androidProperties,
        getUrl
    };
}
