import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue";
import { Tag } from '@leon-hub/tags';
import RouteName from '@leon-hub/routing-config-names';
import { useDisableStep } from 'web/src/modules/two-factor-authentication/components/DisableStep/composables';
import VJumbotron from 'web/src/components/Jumbotron/VJumbotron/VJumbotron.vue';
import TextSlotPlaceholder from 'web/src/components/TextSlotPlaceholder/components/TextSlotPlaceholder.vue';
import { openGoogleAuthenticator } from 'web/src/modules/two-factor-authentication/utils';
import { TfaAppLink } from 'web/src/modules/two-factor-authentication/types';
import VForm from 'web/src/components/Form/components/VForm/VForm.vue';
import SButton from 'web/src/components/Button/SButton/SButton.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DisableStep',
    setup (__props) {
        const tagDiv = Tag.DIV;
        const tfaAppLink = TfaAppLink.GOOGLE_AUTHENTICATOR;
        const tfaRegenerateRouteName = {
            name: RouteName.TWO_FACTOR_AUTHENTICATION_REGENERATE
        };
        const { formRef, formKey, schema, uiSchema, jumbotronProperties, submitDisableHandler } = useDisableStep();
        return (_ctx, _cache)=>{
            const _component_router_link = _resolveComponent("router-link");
            _resolveDirective("data-test");
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['disable-step'])
            }, [
                _createVNode(VJumbotron, _mergeProps(_unref(jumbotronProperties), {
                    class: _ctx.$style['disable-step__jumbotron']
                }), null, 16, [
                    "class"
                ]),
                _createVNode(TextSlotPlaceholder, {
                    class: _normalizeClass(_ctx.$style['disable-step__description']),
                    text: _ctx.$t('WEB2_TFA_DISABLE_INFO'),
                    tag: _unref(tagDiv)
                }, {
                    field1: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['disable-step__description-link']),
                                onClick: _cache[0] || (_cache[0] = ($event)=>_unref(openGoogleAuthenticator)(_unref(tfaAppLink)))
                            }, " Google Authenticator ", 2)
                        ]),
                    field2: _withCtx(()=>[
                            _createElementVNode("span", {
                                class: _normalizeClass(_ctx.$style['disable-step__description-accent'])
                            }, " \"" + _toDisplayString(_ctx.$t('WEB2_DISABLE')) + "\" ", 3)
                        ]),
                    _: 1
                }, 8, [
                    "class",
                    "text",
                    "tag"
                ]),
                _createElementVNode("div", {
                    class: _normalizeClass(_ctx.$style['disable-step__form-wrapper'])
                }, [
                    (_openBlock(), _createBlock(VForm, {
                        ref_key: "formRef",
                        ref: formRef,
                        key: _unref(formKey),
                        class: _normalizeClass(_ctx.$style['disable-step__form']),
                        schema: _unref(schema),
                        "ui-schema": _unref(uiSchema),
                        onSubmit: _unref(submitDisableHandler)
                    }, null, 8, [
                        "class",
                        "schema",
                        "ui-schema",
                        "onSubmit"
                    ])),
                    (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        class: _normalizeClass(_ctx.$style['disable-step__link']),
                        to: tfaRegenerateRouteName
                    }, {
                        default: _withCtx(()=>[
                                _createTextVNode(_toDisplayString(_ctx.$t('JSPACC_SETT_G2SV_REGENERATE_BTN')), 1)
                            ]),
                        _: 1
                    }, 8, [
                        "class"
                    ]))
                ], 2)
            ], 2)), [
                [
                    _directive_auto_id,
                    'DisableStep'
                ]
            ]);
        };
    }
});
