// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fast-option-desktop_mfj_I{display:flex;flex-direction:column;align-items:center}.fast-option-desktop__steps_Op_w1{width:100%}.fast-option-desktop__item_IXrcC{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;width:100%;margin-top:16px;color:var(--TextPrimary)}.fast-option-desktop__item_IXrcC:first-child{margin-top:0}.fast-option-desktop__item-accent_HQ6lv{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}.fast-option-desktop__qr_v7TVa{width:168px;height:168px;padding:9px;margin:53px 0 20px;position:relative}.fast-option-desktop__corner_Oei9J{position:absolute;width:9px;height:9px}.fast-option-desktop__corner_Oei9J:after,.fast-option-desktop__corner_Oei9J:before{position:absolute;display:block;content:"";background-color:var(--Layer2)}.fast-option-desktop__corner_Oei9J:after{width:1px;height:8px}.fast-option-desktop__corner_Oei9J:before{width:8px;height:1px}.fast-option-desktop__corner-top_oKKv3{top:0;left:0}.fast-option-desktop__corner-top_oKKv3:after{top:2px;left:0}.fast-option-desktop__corner-top_oKKv3:before{top:0;left:2px}.fast-option-desktop__corner-top--right_ibyXc{top:0;right:0}.fast-option-desktop__corner-top--right_ibyXc:after{top:2px;right:0}.fast-option-desktop__corner-top--right_ibyXc:before{top:0;right:2px}.fast-option-desktop__corner-bottom_SCHQv{bottom:0;left:0}.fast-option-desktop__corner-bottom_SCHQv:after{bottom:2px;left:0}.fast-option-desktop__corner-bottom_SCHQv:before{bottom:0;left:2px}.fast-option-desktop__corner-bottom--right_evmpL{right:0;bottom:0}.fast-option-desktop__corner-bottom--right_evmpL:after{right:0;bottom:2px}.fast-option-desktop__corner-bottom--right_evmpL:before{right:2px;bottom:0}.fast-option-desktop__item-icon_fq3ID{display:flex;align-items:center;justify-content:center;width:32px;height:32px;margin-top:8px;background-color:var(--ColorButton4);border-radius:50%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fast-option-desktop": `fast-option-desktop_mfj_I`,
	"fast-option-desktop__steps": `fast-option-desktop__steps_Op_w1`,
	"fast-option-desktop__item": `fast-option-desktop__item_IXrcC`,
	"fast-option-desktop__item-accent": `fast-option-desktop__item-accent_HQ6lv`,
	"fast-option-desktop__qr": `fast-option-desktop__qr_v7TVa`,
	"fast-option-desktop__corner": `fast-option-desktop__corner_Oei9J`,
	"fast-option-desktop__corner-top": `fast-option-desktop__corner-top_oKKv3`,
	"fast-option-desktop__corner-top--right": `fast-option-desktop__corner-top--right_ibyXc`,
	"fast-option-desktop__corner-bottom": `fast-option-desktop__corner-bottom_SCHQv`,
	"fast-option-desktop__corner-bottom--right": `fast-option-desktop__corner-bottom--right_evmpL`,
	"fast-option-desktop__item-icon": `fast-option-desktop__item-icon_fq3ID`
};
export default ___CSS_LOADER_EXPORT___;
