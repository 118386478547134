import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { IconName } from '@leon-hub/icons';
import DownloadBadge from 'web/src/modules/two-factor-authentication/components/DownloadBadge/DownloadBadge.vue';
import { BadgeImageUrl } from 'web/src/modules/two-factor-authentication/types';
import SHintBlock from 'web/src/components/HintBlock/SHintBlock/SHintBlock.vue';
import { useEnableStepDownload } from './composables';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EnableStepDownload',
    props: {
        languageTag: {
            default: ''
        },
        googlePlayIconUrl: {
            default: ''
        },
        appleStoreIconUrl: {
            default: ''
        }
    },
    setup (__props) {
        const props = __props;
        const { iosProperties, androidProperties, getUrl } = useEnableStepDownload(props);
        const badgeImageUrlIos = BadgeImageUrl.IOS;
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['download-container'])
            }, [
                !process.env.VUE_APP_OS_IOS && (process.env.VUE_APP_OS_IOS || process.env.VUE_APP_OS_ANDROID) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(DownloadBadge, _mergeProps({
                    key: 0,
                    class: _ctx.$style['ios__link']
                }, _unref(iosProperties), {
                    "image-src": _unref(getUrl)(_unref(badgeImageUrlIos))
                }), null, 16, [
                    "class",
                    "image-src"
                ])),
                !process.env.VUE_APP_OS_ANDROID && (process.env.VUE_APP_OS_IOS || process.env.VUE_APP_OS_ANDROID) ? _createCommentVNode("", true) : (_openBlock(), _createBlock(DownloadBadge, _mergeProps({
                    key: 1,
                    class: _ctx.$style['android__link']
                }, _unref(androidProperties), {
                    "image-src": _unref(getUrl)(_unref(BadgeImageUrl).ANDROID)
                }), null, 16, [
                    "class",
                    "image-src"
                ])),
                _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'EnableStepDownload'
                ]
            ]);
        };
    }
});
