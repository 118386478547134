// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .tfa-regenerate__AGId{padding:16px 8px;padding:16px 24px 32px}html.app__layout--desktop .tfa-regenerate__content_LoUhW{padding:0}html.app__layout--desktop .tfa-regenerate__hint_gioNW{margin-bottom:8px}html.app__layout--desktop .tfa-regenerate__label_q47ef{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:16px;color:var(--TextPrimary)}html.app__layout--desktop .tfa-regenerate__label-link_gf2f4{-webkit-text-decoration:none;text-decoration:none}html.app__layout--desktop .tfa-regenerate__form_OUedc{width:100%;margin-bottom:20px;max-width:364px;margin-bottom:0}html.app__layout--desktop .tfa-regenerate__link_7fXgN{max-width:364px}html.app__layout--desktop .tfa-regenerate__buttons__kGTT{display:grid;row-gap:16px;padding:16px 0}html.app__layout--desktop .tfa-regenerate__custom-padding-bottom_P6qrj{padding-bottom:160px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tfa-regenerate": `tfa-regenerate__AGId`,
	"tfa-regenerate__content": `tfa-regenerate__content_LoUhW`,
	"tfa-regenerate__hint": `tfa-regenerate__hint_gioNW`,
	"tfa-regenerate__label": `tfa-regenerate__label_q47ef`,
	"tfa-regenerate__label-link": `tfa-regenerate__label-link_gf2f4`,
	"tfa-regenerate__form": `tfa-regenerate__form_OUedc`,
	"tfa-regenerate__link": `tfa-regenerate__link_7fXgN`,
	"tfa-regenerate__buttons": `tfa-regenerate__buttons__kGTT`,
	"tfa-regenerate__custom-padding-bottom": `tfa-regenerate__custom-padding-bottom_P6qrj`
};
export default ___CSS_LOADER_EXPORT___;
