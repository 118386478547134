import { defineComponent as _defineComponent } from 'vue';
import { openBlock as _openBlock, createBlock as _createBlock, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue";
import { ref } from 'vue';
import FastOptionDesktopComponent from 'web/src/modules/two-factor-authentication/components/FastOptionDesktop/FastOptionDesktopComponent.vue';
import FastOptionMobileComponent from 'web/src/modules/two-factor-authentication/components/FastOptionMobile/FastOptionMobileComponent.vue';
import ManualOptionShared from 'web/src/modules/two-factor-authentication/components/ManualOptionShared/ManualOptionShared.vue';
export default /*@__PURE__*/ _defineComponent({
    __name: 'EnableStepSetup',
    props: {
        secret: {},
        login: {},
        link: {},
        qrCode: {},
        email: {},
        isFastOptionProp: {
            type: Boolean,
            default: true
        }
    },
    setup (__props) {
        const fastOption = ref(true);
        function toggleOption() {
            fastOption.value = !fastOption.value;
        }
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['setup-container'])
            }, [
                fastOption.value && _ctx.isFastOptionProp ? (_openBlock(), _createBlock(FastOptionDesktopComponent, {
                    key: 0,
                    "qr-code": _ctx.qrCode,
                    email: _ctx.email,
                    onClose: toggleOption
                }, null, 8, [
                    "qr-code",
                    "email"
                ])) : (_openBlock(), _createBlock(ManualOptionShared, {
                    key: 2,
                    secret: _ctx.secret,
                    email: _ctx.email,
                    login: _ctx.login,
                    onClose: toggleOption
                }, null, 8, [
                    "secret",
                    "email",
                    "login"
                ]))
            ], 2)), [
                [
                    _directive_auto_id,
                    'EnableStepSetup'
                ]
            ]);
        };
    }
});
