// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .tfa-login_tw2pE{padding:16px 32px 32px}html.app__layout--desktop .tfa-login__intro_Xq0vf{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;margin-bottom:20px;color:var(--TextPrimary);text-align:center}@media(pointer:coarse){html.app__layout--desktop .tfa-login__google-link_wWZb4{color:var(--TextDefault);-webkit-text-decoration:underline;text-decoration:underline}}html.app__layout--desktop .tfa-login__link_K_0WO{font-size:14px;font-weight:400;line-height:20px;letter-spacing:.25px;display:inline-block;width:100%;padding:12px;margin-top:20px;color:var(--TextPrimary);text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tfa-login": `tfa-login_tw2pE`,
	"tfa-login__intro": `tfa-login__intro_Xq0vf`,
	"tfa-login__google-link": `tfa-login__google-link_wWZb4`,
	"tfa-login__link": `tfa-login__link_K_0WO`
};
export default ___CSS_LOADER_EXPORT___;
