// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html.app__layout--desktop .manual-options_CX462{display:flex;flex-direction:column}html.app__layout--desktop .manual-options__description_EWufl{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;width:100%;padding:0;margin-top:20px}html.app__layout--desktop .manual-options__description_EWufl:first-child{margin-top:0}html.app__layout--desktop .manual-options__description-accent_ymI9M{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}html.app__layout--desktop .manual-options__item_qdIPo{font-size:14px;font-weight:400;line-height:24px;letter-spacing:.25px;width:100%;padding:0 8px;margin:20px 0 8px;color:var(--TextPrimary);text-align:center}html.app__layout--desktop .manual-options__item_qdIPo:last-of-type{margin-bottom:0}html.app__layout--desktop .manual-options__item-accent_wq5sC{font-size:14px;font-weight:500;line-height:20px;letter-spacing:.25px;color:var(--TextDefault)}html.app__layout--desktop .manual-options__item-icon_ZGbhF{display:flex;align-items:center;justify-content:center;width:32px;height:32px;margin-top:8px;background-color:var(--ColorButton4);border-radius:50%}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"manual-options": `manual-options_CX462`,
	"manual-options__description": `manual-options__description_EWufl`,
	"manual-options__description-accent": `manual-options__description-accent_ymI9M`,
	"manual-options__item": `manual-options__item_qdIPo`,
	"manual-options__item-accent": `manual-options__item-accent_wq5sC`,
	"manual-options__item-icon": `manual-options__item-icon_ZGbhF`
};
export default ___CSS_LOADER_EXPORT___;
