// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.download-badge__link_jTSOA{display:inline-flex;align-items:center;min-height:75px;overflow:hidden}.download-badge__image_iPT7Q{display:block;width:176px;height:auto}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"download-badge__link": `download-badge__link_jTSOA`,
	"download-badge__image": `download-badge__image_iPT7Q`
};
export default ___CSS_LOADER_EXPORT___;
