import { defineComponent as _defineComponent } from 'vue';
import { unref as _unref, normalizeClass as _normalizeClass, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective } from "vue";
const _hoisted_1 = [
    "href"
];
const _hoisted_2 = [
    "alt",
    "src"
];
import { MobileAppOs } from 'web/src/modules/app-download/enums';
import vDataTestUnit from 'web/src/testing/directives/vDataTestUnit';
export default /*@__PURE__*/ _defineComponent({
    __name: 'DownloadBadge',
    props: {
        redirectUrl: {
            default: ''
        },
        imageSrc: {
            default: ''
        },
        imageAlt: {
            default: ''
        },
        appType: {}
    },
    setup (__props) {
        return (_ctx, _cache)=>{
            const _directive_auto_id = _resolveDirective("auto-id");
            return _withDirectives((_openBlock(), _createElementBlock("div", {
                class: _normalizeClass(_ctx.$style['download-badge'])
            }, [
                _ctx.redirectUrl ? _withDirectives((_openBlock(), _createElementBlock("a", {
                    key: 0,
                    class: _normalizeClass(_ctx.$style['download-badge__link']),
                    target: "_blank",
                    href: _ctx.redirectUrl
                }, [
                    _ctx.imageSrc ? _withDirectives((_openBlock(), _createElementBlock("img", {
                        key: 0,
                        class: _normalizeClass({
                            [_ctx.$style['download-badge__image']]: true,
                            [_ctx.$style['download-badge__image--android']]: _ctx.appType === _unref(MobileAppOs).Android
                        }),
                        alt: _ctx.imageAlt,
                        src: _ctx.imageSrc
                    }, null, 10, _hoisted_2)), [
                        [
                            _unref(vDataTestUnit),
                            {
                                el: _ctx.appType === _unref(MobileAppOs).Android ? 'download-badge-image-android' : 'download-badge-image'
                            }
                        ]
                    ]) : _createCommentVNode("", true)
                ], 10, _hoisted_1)), [
                    [
                        _unref(vDataTestUnit),
                        {
                            el: 'download-badge-link'
                        }
                    ]
                ]) : _createCommentVNode("", true)
            ], 2)), [
                [
                    _directive_auto_id,
                    'DownloadBadge'
                ]
            ]);
        };
    }
});
